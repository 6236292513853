import React from "react"
import "firebase/auth"
import "firebase/database"
import "bootswatch/dist/yeti/bootstrap.min.css"

import "./src/css/global.css"
import { shouldUpdateScrollFn, wrapPageElementQueryProvider } from "./src/utils/browser_ssr_utils"
import RootElement from "./src/components/rootElement"

export const shouldUpdateScroll = shouldUpdateScrollFn

export const wrapPageElement = wrapPageElementQueryProvider

export const wrapRootElement = ({ element }) => (
  <RootElement>{element}</RootElement>
)