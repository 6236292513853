import React from "react"
import RootElementBaseLosMurales from "acciondigital-basic-theme/src/components/losmurales/rootElementBaseLosMurales"



import infoSitio from "../utils/infoSitio"
console.log("🚀 ~ file: rootElement.js:8 ~ infoSitio.netlifySiteId:", infoSitio.netlifySiteId)

export const RootElement = ({ children }) => (
  <RootElementBaseLosMurales sitio={infoSitio.netlifySiteId}>{children}</RootElementBaseLosMurales>
)

export default RootElement
